<template>
  <div>
    <b-modal
        id="modal-1"
        centered
        v-model="visibleModal"
        :title="$t(shop.id ? 'user-shops.edit-shop.editing' : 'user-shops.create-shop.creating')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div v-if="$store.getters['engine/getEngineName'](shop.engineId) === 'engine.wordpress' && shopWordPressPlugin" class="text-center">
          <h4>Ważność wtyczki WordPress:</h4>
          <h3>{{ dateUtil.asDate(shopWordPressPlugin.currentPeriodEnd) }}</h3>
        </div>

        <div v-if="$store.getters['engine/getEngines']" class="form-group">
          <label>{{  $t('message.engine') }}</label>
          <multiselect
              v-model="shop.engineId"
              :custom-label="id => $t($store.getters['engine/getEngineName'](id))"
              :options="$store.getters['engine/getEnginesIds']"
              placeholder="Wybierz silnik"
              :show-labels="false"
              :allow-empty="false"
              :class="{ 'is-invalid': submitted && $v.shop.engineId.$error }"
          />
          <div v-if="!$v.shop.engineId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div v-if="$store.getters['market/getMarkets'] && !shop.id" class="form-group">
          <label>{{  $t('message.market') }}</label>
          <multiselect
              v-model="shop.marketId"
              :custom-label="id => $t($store.getters['market/getMarketName'](id))"
              :options="$store.getters['market/getMarketsIds']"
              placeholder="Wybierz rynek"
              :show-labels="false"
              :allow-empty="false"
              :class="{ 'is-invalid': submitted && $v.shop.marketId.$error }"
          />
          <div v-if="!$v.shop.marketId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="name">{{$t('message.name')}}</label>
          <input
              id="name"
              v-model="shop.name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.shop.name.$error }"
          />
          <div v-if="submitted && !$v.shop.name.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div v-if="$store.getters['engine/getEngineName'](shop.engineId) !== 'engine.virtual'" class="form-group">
          <ecat-multiselect
              @change="value => shop.industryId = value"
              save-id="id"
              view-id="name"
              :label="$t('message.industry')"
              placeholder="Wybierz branże"
              fetch-one-url="/industry"
              load-url="/industry/list/pagination"
              query-url="/industry/by-name"
              list-name="industries"
              :set-value="shop.industryId"
              param="name"
              :class="{ 'is-invalid': submitted && $v.shop.industryId.$error }"
          />
          <div v-if="submitted && !$v.shop.industryId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <template v-if="$store.getters['engine/getEngineName'](shop.engineId) !== 'engine.virtual'">
          <div class="form-group">
            <label for="name">{{  $t('message.domain') }}</label>
            <input
                id="name"
                v-model="shop.domain"
                @input="event => checkDomainIsExists(event.target.value)"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': (submitted && $v.shop.domain.$error) || shopWithThisDomainAlreadyExists }"
            />
            <div v-if="submitted && !$v.shop.domain.required" class="invalid-feedback">{{ $t('message.required')}}</div>
            <div v-if="submitted && !$v.shop.domain.domain" class="invalid-feedback">{{ $t('user-shops.create-shop.is-domain')}}</div>
            <div v-if="shopWithThisDomainAlreadyExists" class="invalid-feedback">Taka domena jest już używana</div>
          </div>

          <div class="form-group">
            <label for="email">{{  $t('message.shop-email') }}</label>
            <input
                id="email"
                v-model="shop.emailAddress"
                type="email"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.shop.emailAddress.$error }"
            />
            <div v-if="submitted && !$v.shop.emailAddress.required" class="invalid-feedback">{{ $t('message.required')}}</div>
            <div v-if="submitted && !$v.shop.emailAddress.email" class="invalid-feedback">{{ $t('message.required')}}</div>
          </div>
        </template>

        <template v-if="$store.getters['engine/getEngineName'](shop.engineId) !== 'engine.virtual'">
          <h4 class="text-center">{{ $t('user-shops.integration') }}</h4>

          <div class="form-group">
            <label for="google_analytics">Google Analytics</label>
            <input
                id="google_analytics"
                v-model="shop.googleAnalytics"
                type="text"
                name="google_analytics"
                class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="google_tag_manager">Google Tag Manager</label>
            <input
                id="google_tag_manager"
                v-model="shop.googleTagManager"
                type="text"
                name="google_tag_manager"
                class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="facebook_pixel">{{  $t('user-shops.facebook-pixel') }}</label>
            <input
                id="facebook_pixel"
                v-model="shop.facebookPixel"
                type="text"
                name="facebook_pixel"
                class="form-control"
            />
          </div>
        </template>

        <div class="text-right">
          <b-button @click="createOrEditShop" variant="success">{{ $t(shop.id ? 'user-shops.edit-shop.title' : 'user-shops.create-shop.title') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import {email, required} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import {dateUtil} from "@/helpers/date-util";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";

export default {
  computed: {
    dateUtil() {
      return dateUtil
    }
  },
  components: {Multiselect},
  data() {
    return {
      submitted: false,
      visibleModal: false,
      shopWordPressPlugin: null,
      shop: {},

      shopWithThisDomainAlreadyExists: false,
    }
  },

  validations() {
    const defaultFields = {
      name: {required},
      engineId: {required},
      marketId: {required}
    };

    if (this.$store.getters["engine/getEngineName"](this.shop.engineId) === 'engine.virtual') {
      return {
        shop: {
          ...defaultFields
        }
      }
    }

    return {
      shop: {
        ...defaultFields,
        industryId: {required},
        domain: {
          required,
          domain: function (value) {
            return /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value)
          }
        },
        emailAddress: {required, email}
      }
    }
  },

  methods: {
    async checkDomainIsExists(domain) {
      if (!domain) {
        return
      }

      try {
        const {data} = await axios.get(`/shop/user/exists-by-domain/${domain}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        const { status } = data
        this.shopWithThisDomainAlreadyExists = status
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async openModal(shop) {
      if (shop) {
        this.shop = Object.assign({}, shop);
        await this.loadShopWordPressPlugin(shop)
      }

      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false;
      this.submitted = false;
      this.shopWordPressPlugin = null
      this.shop = {};
    },

    async loadShopWordPressPlugin(shop) {
      if (!shop.wordpressPluginMarketplaceOrderId) {
        return
      }

      try {
        const {data} = await axios.get(`/stripe/subscription/order/${shop.wordpressPluginMarketplaceOrderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.shopWordPressPlugin = data
      } catch (error) {
        console.log(error)
      }
    },

    createOrEditShop() {
      this.submitted = true;
      this.$v.shop.$touch();
      if (this.$v.shop.$invalid) {
        return;
      }

      const json = JSON.stringify(this.shop);

      axios.put(`/shop`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(({ data }) => {
        this.hideModal()
        if (!this.shop.id) {
          this.$store.dispatch('shop/addShop', data)
        }

        if (!this.$store.getters["market/getMarketId"] || !this.$store.getters["shop/selectedShopId"]) {
          this.$store.dispatch('market/setSelectedMarketId', data.marketId)
          this.$store.dispatch('shop/setSelectedShopId', data.id)
        }

        Swal.fire("Sukces!", this.$t(this.shop.id ? "user-shops.edit-shop.success" : "user-shops.create-shop.success"), "success")
            .then(() => this.$root.$emit("user-shops-table-reload"));
      }).catch((error) => {
        this.submitted = false;
        errorCatcher.catchErrors(error)
      })
    }
  }

}
</script>