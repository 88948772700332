<template>
  <div>
    <b-modal
        centered
        class="multistore-modal"
        modal-class="multistore-modal"
        id="modal-1"
        v-model="visibleModal"
        title="Subskrybcja Multistore"
        title-class="font-18 font-weight-bold custom-modal-title"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">

      <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
        <div class="row d-flex justify-content-center" :set="currentStoreLimit = getCurrentStoreLimit()">
          <div class="col-12 col-md-3" v-for="(storeLimit, index) in $store.getters['storelimits/getStoreLimits']"
               :key="index">
            <div class="card enterprise-pricing text-center">
              <div class="card-body card-plan"
                   :class="`${currentStoreLimit && currentStoreLimit.id === storeLimit.id ? 'current-pricing-card' : 'selected-pricing-card'} ${storeLimit && storeLimit.title === 'Popularny' ? 'popular-pricing-card' : ''}`">
                <img :src="require(`@/assets/images/multistore/${getImageByProductId(storeLimit.id)}`)" class="mb-2"
                     alt="Ikona">
                <div class="plan-title py-1">{{ storeLimit.title }}</div>
                <div class="plan-subtitle py-1">Biznes bez żadnych ograniczeń</div>
                <div class="annual-plan"
                     :class="`${storeLimit && storeLimit.title === 'Popularny' ? 'highlight' : ''}`">
                  <div class="plan-price mt-2">
                      <span class="pricing-enterprise-value font-weight-semibold"
                            :class="`${storeLimit && storeLimit.title === 'Popularny' ? 'price-highlight-color' : ''}`">
                        ${{ storeLimit.priceWithTax.toFixed(0) }}
                      </span>
                    <sub v-if="storeLimit.priceWithTax > 0" class="font-medium-1 fw-bold"
                         :style="`${storeLimit && storeLimit.title === 'Popularny' ? 'pricing-duration-highlight' : 'pricing-duration'}`">
                      /{{ timeUtil.getHumanDay(storeLimit.subscriptionTimeUnit, true) }}
                    </sub>
                  </div>
                </div>
                <ul class="list-group list-group-circle text-start py-2">
                  <template v-if="storeLimit.description">
                    <template v-if="storeLimit.description.includes('\n')">
                      <li class="list-group-item" v-for="(description, index) in storeLimit.description.split('\n')"
                          :key="index" style="padding: 0.25rem 1.2rem">
                        {{ description }}
                      </li>
                    </template>
                    <template v-else>
                      {{ storeLimit.description }}
                    </template>
                  </template>
                </ul>
                <button
                    @click="createOrder(storeLimit.id)"
                    id="enterprise_pricing_popular" class="btn my-1 waves-effect" style="width: 90%"
                    :class="`${currentStoreLimit && currentStoreLimit.id === storeLimit.id ? 'btn-outline-primary' : 'btn-primary'}`"
                    :disabled="currentStoreLimit && currentStoreLimit.id === storeLimit.id">
                  {{ currentStoreLimit && currentStoreLimit.id === storeLimit.id ? "Obecny plan" : "Wybierz plan" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <stripe-checkout
        ref="checkoutRef"
        v-if="$store.getters['stripe/publishableKey']"
        :pk="$store.getters['stripe/publishableKey']"
        :session-id="stripeId"
    />

  </div>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {StripeCheckout} from "@vue-stripe/vue-stripe";
import {timeUtil} from "@/helpers/time-util";

export default {
  components: {StripeCheckout},

  data() {
    return {
      visibleModal: false,
      stripeId: ""
    }
  },

  computed: {
    timeUtil() {
      return timeUtil
    }
  },

  methods: {

    hideModal() {
      this.visibleModal = false
    },

    async openModal() {
      this.visibleModal = true
    },

    getCurrentStoreLimit() {
      return this.$store.getters["storelimits/getCurrentMultiStore"]
    },

    createOrder(marketplaceProductVariationId) {
      const json = JSON.stringify({
        "marketplaceProductVariationId": marketplaceProductVariationId
      });

      axios.post(`/marketplace/order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(({data}) => {
        this.createStripeSession(data.marketplaceOrderId)
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    createStripeSession(marketplaceOrderId) {
      const json = JSON.stringify({
        "marketplaceOrderId": marketplaceOrderId
      });

      axios.post(`/stripe/session`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        console.log(result)
        if (result.status === 204) {
          window.location = '/dashboard/marketplace/payment_status?status=success&session_id=free'
          return
        }

        const { data } = result
        this.stripeId = data.stripeId
        this.stripeSubmit()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    stripeSubmit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },

    getImageByProductId(id) {
      switch (id) {
        case '6853cba6-70a3-4d5f-a064-55ed0b855cd7':
          return '_mini.png'
        case '962de2c8-aeee-48e8-8e70-b21ad61120af':
          return '_popular.png'
        case '54c03469-4868-41f5-bfff-62dd4ab824e2':
          return '_pro.png'
        default:
          return '_free.png'
      }
    },

  }

}
</script>