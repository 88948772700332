import store from "@/state/store";

export const dateUtil = {
    asDate,
    asDateTime,
    asDateTimeNormal,
    asDateNormal
};

function asDate(value) {
    if (!value) {
        return ''
    }

    const dateOptions = { timeZone: 'Europe/Warsaw', weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };

    const dateFormatter = new Intl.DateTimeFormat(store.getters["translation/getCurrentLocaleOrFallback"], dateOptions);
    return dateFormatter.format(new Date(value));
}

function asDateTime(value) {
    if (!value) {
        return '-'
    }

    const dateOptions = { timeZone: 'Europe/Warsaw', weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', seconds: 'numeric' };

    const dateFormatter = new Intl.DateTimeFormat(store.getters["translation/getCurrentLocaleOrFallback"], dateOptions);
    return dateFormatter.format(new Date(value));
}

function asDateTimeNormal(value) {
    if (!value) {
        return ''
    }

    const dateOptions = { timeZone: 'Europe/Warsaw', month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', seconds: 'numeric' };

    const dateFormatter = new Intl.DateTimeFormat(store.getters["translation/getCurrentLocaleOrFallback"], dateOptions);
    return dateFormatter.format(new Date(value));
}

function asDateNormal(value) {
    if (!value) {
        return ''
    }

    const dateOptions = { timeZone: 'Europe/Warsaw', month: 'numeric', day: 'numeric', year: 'numeric' };

    const dateFormatter = new Intl.DateTimeFormat(store.getters["translation/getCurrentLocaleOrFallback"], dateOptions);
    return dateFormatter.format(new Date(value));
}