<template>
  <b-dropdown
      toggle-class="header-item noti-icon"
      variant="white"
      menu-class="notifications-dropdown-menu"
  >
    <template v-slot:button-content>
      <i class="ri-notification-3-line"></i>
      <span v-if="notifications && notifications.length > 0" class="noti-dot"></span>
    </template>
    <div class="p-3">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="m-0">{{ $t('navbar.dropdown.notification.text') }}</h6>
        </div>
<!--        <div class="col-auto">-->
<!--          <a class="small" href="#!">{{ $t('navbar.dropdown.notification.subtext') }}</a>-->
<!--        </div>-->
      </div>
    </div>
    <simplebar style="max-height: 230px; width: 230px !important;">
      <a class="text-reset notification-item" v-for="(notification, index) in notifications" :key="index">
        <div class="media">
<!--          <div class="avatar-xs mr-3">-->
<!--                    <span class="avatar-title bg-primary rounded-circle font-size-16">-->
<!--                      <i class="ri-shopping-cart-line"></i>-->
<!--                    </span>-->
<!--          </div>-->
          <div class="media-body">
            <h6 class="mt-0 mb-1">{{  $t(`notifications.type.${notification.type}`) }}</h6>
            <div class="font-size-12 text-muted">
              <p class="mb-1">{{ notification.message }}</p>
              <p class="mb-0">
                <i class="mdi mdi-clock-outline"></i>
                {{ dateUtil.asDateTimeNormal(notification.createdAt) }}
              </p>
            </div>
          </div>
        </div>
      </a>
    </simplebar>
    <div class="p-2 border-top" v-if="count > notifications.length">
      <a class="btn btn-sm btn-link font-size-14 btn-block text-center" @click="loadMoreNotifications">
        <i class="mdi mdi-arrow-right-circle mr-1"></i>
        {{ $t('navbar.dropdown.notification.button') }}
      </a>
    </div>
  </b-dropdown>
</template>

<script>
import simplebar from "simplebar-vue";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {dateUtil} from "@/helpers/date-util";

export default {
  computed: {
    dateUtil() {
      return dateUtil
    }
  },

  components: {
    simplebar
  },

  data() {
    return {
      notifications: [],
      count: 0,

      pagination: {
        currentPage: 1,
        perPage: 5
      }
    }
  },

  methods: {
    async loadMoreNotifications() {
      ++this.currentPage
      await this.loadNotifications()
    },

    async loadNotifications() {
      let page = this.pagination.currentPage - 1;
      if (page > 0) {
        page = this.pagination.currentPage * this.pagination.perPage - this.pagination.perPage;
      }

      try {
        const {data} = await axios.get(`/notification/pagination`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            page: page,
            size: this.pagination.perPage
          }
        });

        const { notifications, count } = data

        this.notifications.push(...notifications)
        this.count = count
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  },

  async created() {
    await this.loadNotifications()
  }
}
</script>